<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    >
      <template #title-right>
        <v-blacker-layout align-center>
          <v-blacker-spacer />
          <span
            class="vbt-margin-r-10"
            style="font-size: 17px;"
          >
            All accounts
          </span>
          <v-blacker-switch
            color="info"
            class="custom-search-toggle"
            @change="changeAccountsState"
          />
        </v-blacker-layout>
      </template>
    </vbt-search>

    <vbt-content-box
      :loading="isLoading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <vbt-table
        :headers="headers"
        :items="complaints"
        show-details-action
        @click:details="goToComplaintDetails"
      >
        <template #orderId="{ item: { orderId } }">
          <router-link
            :to="{
              name: 'order',
              params: { id: orderId },
            }"
            class="blue--text darken-1"
            target="_blank"
          >
            {{ orderId }}
          </router-link>
        </template>

        <template #status="{ item: { status } }">
          <complaint-status-label :status="status" />
        </template>

        <template #printClientId="{ item: { printClientId: accId } }">
          {{ accountNameById[accId] }}
        </template>

        <template #printProviderId="{ item: { printProviderId: accId } }">
          {{ accountNameById[accId] }}
        </template>

        <template #created="{ item: { created } }">
          {{ created | formatDate('local', '-') }}
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';
import ComplaintStatusLabel from '../_components/ComplaintStatusLabel.vue';

const dataLoadingOptions = { getterName: 'getComplaints' };
const paginationOptions = { itemsName: 'complaints' };
const searchOptions = {
  initialSearchDataStates: [
    'status', 'type', 'reference', 'printCustomerId', 'printProviderId',
  ],
  customTypes: {
    status: 'number',
    printCustomerId: 'number',
    printProviderId: 'number',
  },
};

const headers = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Status', value: 'status' },
  { text: 'Client', value: 'printClientId' },
  { text: 'Provider', value: 'printProviderId' },
  { text: 'Created Date', value: 'created' },
  { text: 'Main reason', value: 'mainReason' },
]);

export default {
  name: 'IrComplaints',

  components: {
    ComplaintStatusLabel,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState('complaints', ['complaints']),

    ...mapState('enums', {
      complaintStatusSelectOptions(state) {
        const { complaintStatuses = [] } = state.enums;
        return complaintStatuses.map(({ name: text, value }) => ({ text, value }));
      },
    }),

    ...mapGetters('enums', ['complaintStatusByStatusId']),
    ...mapGetters('accounts', ['clientsOptions', 'providersOptions', 'accountNameById']),

    searchFieldsConfig() {
      return [
        {
          value: 'reference',
          label: 'Reference',
          type: 'text',
          flex: 'sm2',
        },
        {
          value: 'status',
          items: this.complaintStatusSelectOptions,
          label: 'Status',
          type: 'combobox',
          flex: 'sm2',
        },
        {
          value: 'printClientId',
          items: this.clientsOptions,
          label: 'Client',
          type: 'combobox',
          flex: 'sm3',
        },
        {
          value: 'printProviderId',
          items: this.providersOptions,
          label: 'Provider',
          type: 'combobox',
          flex: 'sm3',
        },
      ];
    },
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('complaints', ['getComplaints']),

    ...mapActions('accounts', ['getAccounts']),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },

    goToComplaintDetails({ id }) {
      this.$router.push({ name: 'complaint', params: { id } });
    },
  },
};
</script>
