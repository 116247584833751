<template>
  <v-chip
    :color="color"
    small
  >
    {{ complaintStatusByStatusId[status] }}
  </v-chip>
</template>

<script>
import { mapGetters } from 'vuex';

const STATUS_COLORS = {
  closed: 'grey',
  approved: 'light-blue',
  accepted: 'success',
  dispute: 'error',
  arbitrage: 'error',
};

export default {
  name: 'ComplaintStatusLabel',

  props: {
    status: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters('enums', ['complaintStatusByStatusId']),

    color() {
      return STATUS_COLORS[(this.complaintStatusByStatusId[this.status] || '').toLowerCase()];
    },
  },
};
</script>
